section#projekty {
    padding-block: $section-padding;

    section#projects-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 2rem;

        article.project {
            position: relative;
            width: calc(33% - 1.2rem);
            background-color: $body-bg-secondary;
            border-radius: 20px 20px 10px 10px;
            transition: none;
            box-shadow: $box-shadow;

            opacity: 0;
            transform: translateY(20%);

            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

            -webkit-tap-highlight-color: transparent;

            a.github {
                position: absolute;
                right: 8px;
                top: 8px;
                z-index: 1;
                font-size: 2rem;
                background-color: #010409;
                rotate: 0deg;
                cursor: pointer;

                display: flex;
                padding: 5px;
                border-radius: 50%;

                transition: rotate 0.3s ease-in-out, color 0.3s ease-in;

                &:hover {
                    color: $primary-text;
                    rotate: 30deg;
                }

                &:hover + a.img-wrapper img {
                    scale: 1;
                }
            }

            a.img-wrapper {
                overflow: hidden;
                border-radius: 10px 10px 0 0;

                img {
                    transition: scale 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
                    object-fit: cover;
                    height: 100%;
                    object-position: top;
                    vertical-align: middle;

                    &:hover {
                        scale: 1.1;
                    }
                }
            }

            div.text {
                padding: 1rem 1.5rem;

                p {
                    font-weight: 300;
                    color: $text-color-secondary;
                }
            }
        }

        &.projects-show {
            article.project {
                opacity: 1;
                transform: translateY(0);

                &:nth-of-type(1) {
                    transition-delay: 0.2s;
                }

                &:nth-of-type(2) {
                    transition-delay: 0.4s;
                }

                &:nth-of-type(3) {
                    transition-delay: 0.6s;
                }
            }
        }
    }

    p.coming-soon {
        padding-top: 3rem;
        text-align: center;
        color: $text-color-secondary;

        opacity: 0;
        transform: translateY(20%);
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

        &.coming-soon-show {
            opacity: 1;
            transform: translateY(0);
        }
    }

    div.button-wrapper {
        margin-top: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;

        button.primary-button {
            opacity: 0;
            transform: translateY(20%);

            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

            &.button-show {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    @include media(xl) {
        section#projects-wrapper {
            justify-content: center;
            article.project {
                width: calc(50% - 1rem);

                &:first-child {
                    width: 50%;
                }
            }
        }
    }

    @include media(md) {
        padding-block: $section-padding-md;
        padding-inline: $section-padding-sm;

        section#projects-wrapper {
            article.project {
                width: 75%;

                &:first-child {
                    width: 75%;
                }
            }
        }
    }

    @include media(sm) {
        section#projects-wrapper {
            article.project {
                width: 90%;

                &:first-child {
                    width: 90%;
                }
            }
        }
    }
}
