@import './var';

@for $weight from 1 through 9 {
    $font-weight: $weight * 100;
    @font-face {
        font-family: $font-family;
        font-weight: $font-weight;
        font-display: swap;
        src: url('../../assets/fonts/#{$font-family}-#{$font-weight}.ttf') format('truetype');
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $font-family;
}

html {
    font-size: $font-size;
    color: $text-color-primary;
    line-height: 1.2;
    scroll-behavior: smooth;
}

body {
    background-color: $body-bg-primary;
}

#root {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 10px;
    font-weight: 400;
}

h1 {
    font-size: $h1-font-size;
}
h2 {
    font-size: $h2-font-size;
}
h3 {
    font-size: $h3-font-size;
}
h4 {
    font-size: $h4-font-size;
}
h5 {
    font-size: $h5-font-size;
}

ul,
ol {
    list-style-position: inside;
}

p {
    font-size: $p-font-size;
}

a {
    color: $text-color-primary;
    font-size: $a-font-size;
    text-decoration: none;
    display: inline-block;
    transition: color 0.2s ease-in-out;

    &:hover {
        color: $primary-text;
    }
}
