section#doswiadczenie {
    padding-block: $section-padding;
    background-color: $body-bg-secondary;

    .module {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    section#experience-timeline {
        position: relative;
        width: 100%;

        &::after {
            content: '';
            position: absolute;
            width: 6px;
            height: 0;
            background-color: rgba(#fff, 0.3);
            border-radius: 10px;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            transition: height 0.4s ease-in-out;
        }

        article.one-position {
            position: relative;
            width: 50%;
            padding-inline: 40px;
            opacity: 0;
            transition: opacity 0.4s ease-in-out;

            &:not(:first-of-type) {
                margin-top: -35px;
            }

            &::after {
                content: '';
                position: absolute;
                top: 15%;
                right: 0;
                transform: translateX(50%);
                width: 25px;
                aspect-ratio: 1;
                background-color: mix(#000, #fff, 25%);
                border-radius: 50%;
            }

            &.left {
                left: 50%;
                transform: translateX(-100%);

                &::before {
                    content: '';
                    position: absolute;
                    z-index: 2;
                    top: 15%;
                    right: 30px;
                    border: medium solid mix(#fff, $body-bg-primary, 2%);
                    border-width: 10px 0 10px 10px;
                    border-color: transparent transparent transparent mix(#fff, $body-bg-primary, 2%);
                }
            }

            &.right {
                left: 50%;

                &::before {
                    content: '';
                    position: absolute;
                    z-index: 2;
                    top: 15%;
                    left: 26px;
                    border: medium solid mix(#fff, $body-bg-primary, 2%);
                    border-width: 15px 15px 15px 0;
                    border-color: transparent mix(#fff, $body-bg-primary, 2%) transparent transparent;
                }

                &::after {
                    left: 0;
                    transform: translateX(-50%);
                }
            }

            div.content {
                background-color: mix(#fff, $body-bg-primary, 2%);
                text-align: center;
                color: #fff;
                border-radius: 6px;
                padding: 20px 30px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                h3 {
                    font-size: 2rem;
                }

                p.date {
                    color: $primary-text;
                    font-size: 1.25rem;
                }

                p.position {
                    color: $text-color-secondary;
                }
            }
        }

        &.timeline-show {
            &::after {
                height: 100%;
            }

            article.one-position {
                opacity: 1;

                &:nth-of-type(1) {
                    transition-delay: 0.2s;
                }

                &:nth-of-type(2) {
                    transition-delay: 0.4s;
                }

                &:nth-of-type(3) {
                    transition-delay: 0.6s;
                }

                &:nth-of-type(4) {
                    transition-delay: 0.8s;
                }
            }
        }
    }

    @include media(xxl) {
        section#experience-timeline {
            article.one-position {
                width: 50%;
            }
        }
    }

    @include media(lg) {
        section#experience-timeline {
            &::after {
                left: 0;
            }

            article.one-position {
                width: 100%;
                padding-inline: 30px 0;

                &:not(:first-of-type) {
                    margin-top: 20px;
                }

                &.left {
                    left: 100%;
                    transform: translateX(-100%);

                    &::before {
                        content: '';
                        position: absolute;
                        z-index: 2;
                        top: 15%;
                        left: 20px;
                        right: unset;
                        border: medium solid mix(#fff, $body-bg-primary, 2%);
                        border-width: 15px 15px 15px 0;
                        border-color: transparent mix(#fff, $body-bg-primary, 2%) transparent transparent;
                    }

                    &::after {
                        left: 0;
                        transform: translateX(-50%);
                    }
                }

                &.right {
                    left: 100%;
                    transform: translateX(-100%);

                    &::before {
                        left: 20px;
                    }

                    &::after {
                        left: 0;
                        transform: translateX(-50%);
                    }
                }

                div.content {
                    h3 {
                        font-size: 1.5rem;
                    }

                    h5,
                    p.position, p.date {
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    @include media(md) {
        padding-block: $section-padding-md;
        padding-inline: $section-padding-sm;
    }

    @include media(sm) {
        section#experience-timeline {
            article.one-position {
                div.content {


                    h3 {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}
